import React, { useRef, useState, useContext } from 'react'
import styled from 'styled-components'
import { navigate } from "gatsby"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination} from 'swiper/core';
import PixiContext from "../context/pixiContext";
import 'swiper/swiper-bundle.min.css';
import Seo from '../components/global/Seo'
import timLogo from "../images/index/tim-logo.jpg"
import pencil from "../images/index/pen.png"
import useKioskMode from "../hooks/useKioskMode"

import decoPatternBottom1 from "../images/index/deco-bottom-1.jpg"
import decoPatternBottom2 from "../images/index/deco-bottom-2.jpg"
import decoPatternBottom3 from "../images/index/deco-bottom-3.jpg"
import decoPatternBottom4 from "../images/index/deco-bottom-4.jpg"


import decoPatternTop1 from "../images/index/deco-top-1.jpg"
import decoPatternTop2 from "../images/index/deco-top-2.jpg"
import decoPatternTop3 from "../images/index/deco-top-3.jpg"
import decoPatternTop4 from "../images/index/deco-top-4.jpg"
import decoPatternTop5 from "../images/index/deco-top-5.jpg"

import bigCircle from "../images/index/circle.svg"
import smallCircle from "../images/index/smallCircle.svg"

import scissors from "../images/index/schere.png"
import figurette from "../images/index/figurette.png"
import button from "../images/index/button.png"

import logos from "../images/index/logos-final-2.jpg"

import ProgressBar from '../components/index/ProgressBar'



// Install modules
SwiperCore.use([ Pagination]);

const PageWrapper = styled.div`
  background-color:${props => props.animate === "true" ? "white" : "black" };
  height:100vh;
  width:100vw;
  color:white;
  padding-left:11%;
  padding-top:8%;
  transition: background-color 0.8s ease-in-out;
  overflow: hidden;
  @media (max-height:700px){
    padding-left:5%;
    padding-top:3%;
  }

`

const TopWrapper = styled.div`
  position:relative;
  top:${props => props.animate === "true" ? "-1200px" : "0" };
  transition: all 0.6s ease-in-out;
  z-index:50;
`

const Title = styled.h1`
  color:white;
  font-size:min(11vw,150px);
  font-weight: 700;
`

const SubTitle = styled.h2`
  font-size:min(2.1vw,28px);
 // line-height:min(11vw,55px;
 line-height:1.1vh;
  text-transform:uppercase;
`

const IntroSliderText = styled.p`
  line-height:26px;
`

const IntroSliderWrapper = styled.div`
  margin-top:35px;
  width: 400px;
  @media (max-height:700px){
    width: 470px;
  }
`

const PaginationContainer = styled.div`
  .swiper-pagination-bullet{
    background-color: #fff;
    margin-right:10px;
    margin-bottom:10px;
  }
`

const PaginationWrapper =  styled.div`
  height:20px;
`

const DecoPattern = styled.img`

  position: absolute;
  width:${props => props.width};
  ${props => props.right && `right: ${props.right}`};
  ${props => props.left && `left: ${props.left}`};
`

const DecoPatternBottom = styled(DecoPattern)`
  bottom: ${props => props.animate === "true" ? props.endBottom : props.startBottom };
  transition: bottom 0.8s ease-in-out;
`

const DecoPatternTop = styled(DecoPattern)`
  top: ${props => props.animate === "true" ? props.endTop : props.startTop};
  transition: top 0.8s ease-in-out;
`

const BigCircleTop = styled.img`
  position:absolute;
  width:${props => props.width}%;
  top:-85%;
  left:-28%;
  ${props => props.animate === "true" && "transform:rotate(180deg);"}
  transition: all 2.25s ease-in-out;
`

const BigCircleBottom = styled.img`
  width:${props => props.width}%;
  position:absolute;
  ${props => props.animate === "true" && "transform:rotate(180deg);"}
  bottom:-100%;
  right:-36%;
  transition: all 2.25s ease-in-out;
`


const Logos = styled.a`
  display:block;
  position: absolute;
  max-width:200px;
  ${props => props.right && `right: ${props.right}`};
  ${props => props.left && `left: ${props.left}`};
  bottom: ${props => props.animate === "true" ? props.endBottom : props.kioskMode ? `calc(${props.startBottom} + 60px)` : props.startBottom };
  transition: bottom 0.8s ease-in-out;
  z-index:99;

  >img{
    max-width:100%;
  }

`


const IndexPage = () => {
  const [animate, setAnimate] = useState()
  const { progress } = useContext(PixiContext);
  const paginationRef = useRef(null)
  const [kioskMode, setKioskMode] = useKioskMode();
  console.log(kioskMode)
  const handleStartClick = () =>{
    setAnimate(!animate)
    setTimeout(() => {
      navigate("/entdecken")
    },700)
  }

  const sliderContent = [
    {
      title:"Einführung",
      text: "Gehe mit uns auf Entdeckungsreise! Im »tim« lagern über 550 Musterbücher der ehemaligen Neuen Augsburger Kattunfabrik (NAK). In ihnen verbirgt sich weit über eine Million Stoffdruckmuster aus 200 Jahren europäischer Mode- und Designgeschichte. Erkunde über 3.000 verschiedene Muster. Lass‘ Dich von völlig neuen Design-Kreationen einer Künstlichen Intelligenz (KI) überraschen und entwirf Dein eigenes Design!"
    },
    {
      title:"Entdecken",
      text: "Entdecke die Welt der Muster nach Themen oder nach Farben sortiert! Du kannst sie nach bestimmten Zeiträumen oder Kategorien anordnen und dabei mehr über einzelne Motive und Bezeichnungen in Erfahrung bringen. Lass Dich überraschen!"
    },
    {
      title:"Erleben",
      text: "Begib Dich mit unserer Künstlichen Intelligenz (KI) auf eine außergewöhnliche Muster-Reise! Auf der Grundlage der historischen Muster kreiert die KI eine beinahe unendliche Zahl an neuen, ästhetisch verblüffenden Mustern. Speichere Deine Favoriten und entwirf Dein eigenes Design!"
    },
    {
      title:"Entwerfen",
      text: "Modesilhouetten aus verschiedenen Zeitepochen laden Dich ein, selbst kreativ zu werden. Stelle aus den originalen oder von der KI neu generierten Stoffmustern Deine eigenen Musterfavoriten zusammen. Deiner Fantasie sind dabei keine Grenzen gesetzt!"
    },

  ]

  return (
    <PageWrapper animate={animate ? "true" : ""}>
      <Seo></Seo>
      <TopWrapper animate={animate ? "true" : ""}>
        <Title>CALICO</Title>
        <SubTitle>Entdecken. Erleben. Entwerfen.</SubTitle>
     
        <IntroSliderWrapper>
          <PaginationWrapper>
            <PaginationContainer ref={paginationRef}></PaginationContainer>
          </PaginationWrapper>
          <Swiper
            onInit={(swiper) => {
              swiper.params.pagination.el = paginationRef.current;
              swiper.pagination.init();
              swiper.pagination.render();
              }}
            pagination={{
              clickable: true,
              el:paginationRef.current
            }}
            slidesPerView={1}
          >
            {sliderContent.map((slide,index) => (
              <SwiperSlide key={index}>    
                <IntroSliderText>{slide.text}</IntroSliderText>
              </SwiperSlide>

            ))}
          </Swiper>
        <div onClick={handleStartClick}>  <ProgressBar progress={progress}/> </div>  
        </IntroSliderWrapper>
      </TopWrapper>
      <DecoPatternTop animate={animate ? "true" : ""} src={decoPatternTop1} startTop={"9%"} endTop={"-500px"} left={"0"} width={"7%"}/>
      <DecoPatternTop animate={animate ? "true" : ""} src={decoPatternTop2} startTop={"25%"} endTop={"-500px"} left={"0"} width={"8.2%"}/>
      <DecoPatternTop animate={animate ? "true" : ""} src={decoPatternTop3} startTop={"0"} endTop={"-500px"} left={"10%"} width={"15%"}/>
      <DecoPatternTop animate={animate ? "true" : ""} src={decoPatternTop4} startTop={"0"} endTop={"-500px"} left={"27%"} width={"6%"}/>
      <DecoPatternTop animate={animate ? "true" : ""} src={decoPatternTop5} startTop={"0"} endTop={"-500px"} right={"7%"} width={"15%"}/>
      <DecoPatternTop animate={animate ? "true" : ""} src={timLogo} startTop={"6%"} endTop={"-500px"} left={"3%"} width={"7.8%"}/>
      <DecoPatternTop animate={animate ? "true" : ""} src={smallCircle} startTop={"7%"} endTop={"-500px"} right={"12%"} width={"8.5%"}/>
      <DecoPatternBottom animate={animate ? "true" : ""} src={figurette} startBottom={"0%"} endBottom={"-100%"} right={"28%"} width={"20.5%"}/>
      <DecoPatternTop animate={animate ? "true" : ""} src={pencil} startTop={"0%"} endTop={"-500px"} right={"10%"} width={"30%"}/>
      <DecoPatternBottom animate={animate ? "true" : ""} src={button} startBottom={"44%"} endBottom={"-500px"} right={"31%"} width={"9%"}/>
      <DecoPatternBottom animate={animate ? "true" : ""} src={decoPatternBottom4} startBottom={"33%"} endBottom={"-500px"} right={"0"} width={"9.5%"}/>
      <DecoPatternBottom animate={animate ? "true" : ""} src={decoPatternBottom2} startBottom={"0%"} endBottom={"-500px"} right={"14%"} width={"31.25%"}/>
      <DecoPatternBottom animate={animate ? "true" : ""} src={decoPatternBottom3} startBottom={"0%"} endBottom={"-500px"} right={"10%"} width={"8.2%"}/>
      <DecoPatternBottom animate={animate ? "true" : ""} src={decoPatternBottom1} startBottom={"0%"} endBottom={"-500px"} right={"33%"} width={"24.6%"}/>
      <DecoPatternBottom animate={animate ? "true" : ""} src={scissors} startBottom={"0%"} endBottom={"-500px"} left={"18%"} width={"65.9%"}/>
      <BigCircleTop animate={animate ? "true" : ""} src={bigCircle} width={"60"}/>
      <BigCircleBottom animate={animate ? "true" : ""} src={bigCircle} width={"78"}/>
      <Logos 
        href="https://www.kulturstiftung-des-bundes.de/de/projekte/erbe_und_vermittlung/detail/dive_in_programm_fuer_digitale_interaktionen.html" target="_blank" rel="noreferrer"
        animate={animate ? "true" : ""}  
        startBottom={"2%"} 
        endBottom={"-500px"} 
        right={"3.5%"} 
        kioskMode={kioskMode}
       
      >
        <img src={logos} max-width={"50px"}/>
      </Logos>
    </PageWrapper>
  )
}

export default IndexPage

import React from 'react';
import styled from 'styled-components';

const ProgressContainer = styled.div`
  position: relative;
  margin-top:40px;
  width:16vw;
  height:50px;  
  background-color:#ffffff40;
  cursor:pointer;
  z-index:99;
  border:2px solid black;
`

const ProgressBarFiller = styled.div`
  width:${props => props.progress}%;
  background-color:black;
  height:100%;
  display:grid;
  align-items: center;
  padding-left:10px;
  transition: width 0.1s ease-in-out;
  span {
    color:white
    font-size:1.1vw;
    font-family: 'Avenir Next',sans-serif;
    font-weight: 700;
    min-width:200px;
    text-transform:uppercase;
  }
`

const ProgressBar = ({progress}) => {
  return (
    <ProgressContainer>
      <ProgressBarFiller progress={progress}>
        <span>{progress < 100 ? `${progress} % geladen` : "Zur Mustersammlung"}</span>
      </ProgressBarFiller>
    </ProgressContainer>
  )
}

export default ProgressBar